<template>
  <authorize :permission="permission">
    <slot />
    <template
      v-if="permissionFallbackComponent || permissionFallbackType"
      slot="fallback"
      slot-scope="{}"
    >
      <component
        :is="permissionFallbackComponent"
        v-if="permissionFallbackComponent"
      />
      <template v-else>
        <upgrade-now-notice :type="permissionFallbackType" :upgrade-url="permissionFallbackTo" />
        <fake-data-ribbon>
          <fake-data-image-placeholder :type="permissionFallbackType" />
        </fake-data-ribbon>
      </template>
    </template>
  </authorize>
</template>

<script>
/**
 * Checks a route for permission and renders the slot if it does.
 * If not, it tries to find a sensible fallback.
 * @module AuthorizeRoutedWithFallback
 * @example
 * Use a similar meta data to define a permission and fallbacks
 * meta: {
 *   permission: 'clippings.list',
 *   permissionFallbackType: 'clippings',
 *   permissionFallbackComponent: 'ClippingsAuthorizeFallback'
 * }
 * Use permissionFallbackType to specify the type to use for notice and fallbackImage combo
 * Use permissionFallbackComponent to specify a custom component.
 * The component has precedence over type.
 */
export default {
  name: 'AuthorizeRoutedWithFallback',
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Returns the permission for the current route or true so it can continue
     * @return {string|boolean}
     */
    permission () {
      return this.route.meta.permission || true
    },

    /**
     * Returns a possible fallback component
     * @type {Function<Promise<Component>>||boolean}
     */
    permissionFallbackComponent () {
      if (!this.route.meta.permissionFallbackComponent) return false
      return this.route.meta.permissionFallbackComponent
    },
    /**
     * Returns the possible fallback type to display the FakeDataImagePlaceholder and UpdateNownotice
     * @type {string|boolean}
     */
    permissionFallbackType () {
      return this.route.meta.permissionFallbackType || false
    },

    permissionFallbackTo () {
      return this.route.meta.permissionFallbackTo
    }
  }
}
</script>
